import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { t } from "i18next";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";

import Quizz from "../components/Quizz/Quizz";

import { useAuth } from "@/modules/auth/AuthContext";
import Carousel from "@/modules/common/components/Carousel";
import TitledPage from "@/modules/common/components/layout/pages/TitledPage";
import SolutionCard from "@/modules/common/components/SolutionCard";
import bets from "@/modules/home/assets/banner_bets.png";
import pais from "@/modules/home/assets/banner_dia_dos_pais.png";
import voluntariado from "@/modules/home/assets/banner_voluntariado.png";
import pdf_bets from "@/modules/home/assets/bets.pdf";
import pdf_pais from "@/modules/home/assets/dia_dos_pais.pdf";
import inapp from "@/modules/home/assets/in-app.jpg";
import iva from "@/modules/home/assets/iva.jpg";
import tracking from "@/modules/home/assets/tracking.jpg";
import pdf_voluntariado from "@/modules/home/assets/voluntariado.pdf";

const carouselItems = [
  {
    title: "É possível frear as bets?",
    description:
      "Mais de ¼ da população adulta brasileira já faz apostas esportivas online. Confira a pesquisa feita pelo PiniOn com 1.500 pessoas de uma amostra representativa Brasil.",
    imgPath: bets,
    onClick: () => {
      window.open(pdf_bets);
    },
  },
  {
    title: "Qual o comportamento de consumo no Dia dos Pais?",
    description:
      "Confira alguns dos insights sobre as preferências de compra para o Dia dos Pais do brasileiro.\nA pesquisa foi feita em agosto de 2024 com 1.500 respondentes de uma amostra representativa Brasil.",
    imgPath: pais,
    onClick: () => {
      window.open(pdf_pais);
    },
  },
  {
    title: "Somos um país altruísta?",
    description:
      "No Dia Nacional do Voluntariado, celebramos a generosidade e o impacto positivo de dedicar tempo e esforço a uma causa.\nConfira a pesquisa realizada com 1500 pessoas de uma amostra representativa Brasil para entender qual é a relação do brasileiro com o voluntariado.",
    imgPath: voluntariado,
    onClick: () => {
      window.open(pdf_voluntariado);
    },
  },
];

const Home = () => {
  const { user } = useAuth();
  const userFirstName = user?.name.split(" ")[0] ?? "";

  return (
    <TitledPage title={`${t("Hi")}, ${userFirstName}`}>
      <Grid container spacing={3} sx={{ mb: "40px" }}>
        <Grid item xs={12} lg={8} sx={{ display: "flex" }}>
          <Box sx={{ flexGrow: 1, minHeight: "320px", maxWidth: "100%" }}>
            <Carousel items={carouselItems} />
          </Box>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Quizz />
        </Grid>
      </Grid>
      <Stack sx={{ pb: 5 }}>
        <Stack direction="row" sx={{ mb: "12px" }}>
          <Typography
            sx={{
              flexGrow: 1,
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            <Trans>Discover our solutions</Trans>
          </Typography>
          <Link to="/products">
            <Button variant="text" size="small">
              <Trans>See all</Trans>
            </Button>
          </Link>
        </Stack>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6} xl={3}>
            <SolutionCard
              badge="Midia"
              title="IVA"
              description="Um sistema de predição escalável e ágil capaz de identificar rapidamente o que tem maior potencial para atrair a atenção do olho humano."
              imgPath={iva}
              onClickLearnMore={() => {
                window.open("https://pinion.app/solucao/iva/");
              }}
            />
          </Grid>
          <Grid item xs={12} lg={6} xl={3}>
            <SolutionCard
              badge="Inteligencia competitiva"
              title="INApp"
              description="Nossa plataforma coleta conteúdo de aplicativos, aplica OCR e extrai informações como preços, promoções, horários e métodos de pagamento em minutos."
              imgPath={inapp}
              onClickLearnMore={() => {
                window.open("https://pinion.app/solucao/in-app/");
              }}
            />
          </Grid>
          <Grid item xs={12} lg={6} xl={3}>
            <SolutionCard
              badge="Inteligencia competitiva"
              title="Tracking de comportamento"
              description="Avalie o reconhecimento e demanda do produto/serviço, defina uma estratégia de marca apropriada e avalie o impacto das ações em mídia."
              imgPath={tracking}
              onClickLearnMore={() => {
                window.open("https://pinion.app/solucao/tracking-de-marcas/");
              }}
            />
          </Grid>
        </Grid>
      </Stack>
    </TitledPage>
  );
};

export default Home;
